export const faqs = [
    {
      id: 1,
      question: "How do I reset my password?",
      answer: `To reset or recover lost passwoard visit this <a href="https://students.bu.edu.so/recover" target="_blank" className="text-blue-500 underline">page</a>.`,
    },
    {
      id: 2,
      question: "How do I contact support?",
      answer:
        `You can contact IT support via this <a href="mailto:support@bu.edu.so" target="_blank" className="text-blue-500 underline">Email</a>`,
    },
    {
      id: 3,
      question: "Where can I find student marks?",
      answer:
        `You can find student marks at this page if you have credentials like student-ID and Password <a href="https://students.bu.edu.so/login" target="_blank" className="text-blue-500 underline">here</a>`,
    },
    // Add more FAQs here
  ];