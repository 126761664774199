export const message = {
    title: "Announcing the Glorious 17th Graduation Ceremony of Benadir University!",
    body: `Dear esteemed students and parents,
    We are delighted to share the exciting news of the forthcoming graduation ceremony at Benadir University, where we will honour and celebrate the remarkable achievements of our talented graduating class of 2023. This momentous occasion marks a significant milestone in the lives of our students and the entire Benadir University community.
    This grand event promises to be an unforgettable celebration of knowledge, resilience, and growth. It will take place on Thursday, November 16, 2023, at Sahal Terminal, Mogadishu, Somalia. Our 17th graduation ceremony will serve as a testament to the dedication, perseverance, and hard work exhibited by our graduates throughout their academic journey.
    The graduation ceremony will mark the culmination of years of learning, discovery, and personal growth. It will be a time for reflection, gratitude, and an optimistic outlook towards the future. Faculty members, distinguished guests, parents, and friends will attend the event to celebrate and express their heartfelt congratulations to our graduates.
    As the university, we invite our graduating class of 2023 to attend the ceremony with their  friends and family. 
    In addition, we encourage our students to stay tuned for all the key information about the event (comprehensive schedule, special performances, keynote speakers, dress code, security measures, invitation cards, and event access) through our university official website and social media platforms.
    Finally, congratulations to the Class of 2023! We eagerly anticipate celebrating this milestone moment with you, your loved ones, and the entire Benadir University community.
    With great pride and excitement, 

    Rector’s office,

    Benadir University
    `
}


export const addmi = {
    title:`Admission to Undergraduate Programs  for the 2024 - 2025 Academic Year`,
    body:`Benadir University invites applications from qualified candidates for admission to undergraduate programs for the 2024 - 2025 academic year.

<strong>Sponsorship:</strong>  
Some undergraduate programs offer scholarship opportunities. Applicants interested in scholarships are encouraged to apply early.

For more information, please visit our headquarters or contact us using the details below:

Website: <a className="text-blue-500 underline" href="https://bu.edu.so/admision" target="_blank">https://bu.edu.so/admision </a>

Email : <a className="text-blue-500 underline" href="mailto:admission@bu.edu.so" target="_blank">admission@bu.edu.so</a> or <a className="text-blue-500 underline" href="mailto:registrar@bu.edu.so" target="_blank">registrar@bu.edu.so</a>

Call Center: +2018

Join to our <a className="text-blue-500 underline" href="https://l.facebook.com/l.php?u=https%3A%2F%2Fchat.whatsapp.com%2FGO47OXbzR4cJ9ok9DUGUlU%3Ffbclid%3DIwZXh0bgNhZW0CMTAAAR03euCsmTrL15auY170EhOJTbISN49WoQo1j5La9MGx8jkIs__k39lfnIs_aem_WR5X35Rv0FAsh4rL8wG66Q&h=AT2L7gWEICCXD_oIvzbHsstrqQiCWp8DVwE6UmQF94ModKrOo5COE8Db7-xnpjAMBYRdYiZEbhU-e3pa6B_j0M6rpRecflZny5AvMa1rfUJxSCPRqac3XwP78tHxETa3Sw&__tn__=-UK-R&c[0]=AT3wWVXyemkC2UpOhqsjlQPsbxoWj7fBtMtjGFsd1jTp6_MnIczxScUkxkCc-GA-6mszKQ1xfkdAI0LfQS11pmWgGOJo4_86lEWm-14AAlB8id_23xuLs6rbyAa9NyPcjxwspLklLMZZeago2xDrKpMEKAGHNd4PcXuP2xehdHHnwD_GqbJxxOUAwApFclxWfmaieD2ZIKs9NiR8vEM4VqhS7a_dBhHHueKVApUmGM2TvtMXW9nSB80ezw" target="_blank">Whatsapp community.</a>

Send your <a className="text-blue-500 underline" href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwa.me%2F252614409526%3Ffbclid%3DIwZXh0bgNhZW0CMTAAAR3bSf5vlUhDwG1JZamLdtu0DMWl0foJGa4E4D2e2o_k8En4RBRTLCchCvQ_aem_JxA2305xeLmqyANPpBgtCg&h=AT0vc9ZeOuJFtutMGI1YCp-_dz3__4Mjk6R_75d29szDAy6Hh5LCGeuIFVkBDshKWbAITZMTQwL7VMm6IyKW3f2tolWS744qh2fan6bFykPTIazu2eV2KX8gLqI_GXFs3Q&__tn__=-UK-R&c[0]=AT1ehrQGpfrEPL-8ghlizztgK6ONN0XDBepCpVEm3ilL-2b5CW_7oVXwb6qcedlejggYszOFZ6ZcknCaV9Jzq4rnfThiztlhS-K6Jc2ECWOACW5A_572Po5aFn4p5UsoI4ctM7JryqBrCDC2Z_4b26RLcFMbe2PLwaJkOj7tVgJAQpLHS9jDZeQAF70ubRmiLYA7yRnysdVHNSYe7tcVH67O_PGHx3QAXdYi" target="_blank">messages.</a>

Fill out this <a className="text-blue-500 underline" href="https://l.facebook.com/l.php?u=https%3A%2F%2Fforms.gle%2FyqLVVakR6kiTCNp58%3Ffbclid%3DIwZXh0bgNhZW0CMTAAAR3sJCbgP5Ix5jj-vbQ499TsB1k_RKqotxbIKu--M4DTKsjMKv1pdQLAohc_aem_YP0NlIOQ4RxRjl2pk3VLHA&h=AT21lfW17Eax8F0A6YG9Su7nmgCFISL06CcsRZpi8X2tHXmLlbcGy4NO5xQmTiNu54F2iztjfDv0OZsKU_T4e-RrsZMFMi992yHRxZk1k85-pViJNSe7-o9jc5z7qHYw8A&__tn__=-UK-R&c[0]=AT3wWVXyemkC2UpOhqsjlQPsbxoWj7fBtMtjGFsd1jTp6_MnIczxScUkxkCc-GA-6mszKQ1xfkdAI0LfQS11pmWgGOJo4_86lEWm-14AAlB8id_23xuLs6rbyAa9NyPcjxwspLklLMZZeago2xDrKpMEKAGHNd4PcXuP2xehdHHnwD_GqbJxxOUAwApFclxWfmaieD2ZIKs9NiR8vEM4VqhS7a_dBhHHueKVApUmGM2TvtMXW9nSB80ezw" target="_blank">form.</a> to receive more information about admission.


Thank you for choosing Benadir University!"`
}