export const medDept = {
  desc: "At faculty of Medicine, currenlty we offer only one program which is ",
  dep: [`<p className="text-xl font-semibold">Medicine & Surgery (MBChB).</p>
  <p className="text-base my-2">The Faculty of Medicine & Surgery is a dynamic and prestigious academic institution dedicated to the education and training of future medical professionals. It offers comprehensive programs that encompass both theoretical knowledge and practical skills necessary for a career in medicine and surgery. Through rigorous coursework, hands-on clinical training, and research opportunities, students are equipped with the expertise to diagnose, treat, and prevent diseases across various medical specialties. The faculty boasts state-of-the-art facilities, world-class faculty members, and a supportive learning environment that fosters collaboration and innovation. Graduates emerge as compassionate healthcare professionals, prepared to address the evolving healthcare needs of society and make meaningful contributions to the field of medicine and surgery..</p>`,],
};

export const eduDept = {
  desc: `The Faculty of Education is dedicated to shaping the next generation of educators and leaders through rigorous academic programs and practical training. It offers a diverse range of undergraduate degrees designed to prepare students for dynamic careers in teaching, administration, and educational research. `,
  dep: [
    `<p className="text-xl font-semibold">BSc in Biology major & Chemistry minor.</p>
    <p className="text-base my-2">In the Bachelor of Science in Biology with a Chemistry minor at the Faculty of Education, students will delve into a comprehensive study of biological sciences, exploring the complexity of living organisms, their systems, and their interactions with the environment. This major provides a strong foundation in key areas such as genetics, microbiology, ecology, and physiology. Complementing their biological studies, the Chemistry minor equips students with essential knowledge in organic, inorganic, and physical chemistry, enhancing their understanding of biochemical processes and laboratory techniques. This interdisciplinary approach prepares students for a diverse range of careers in education, research, healthcare, and environmental management, while also fostering critical thinking and scientific inquiry skills crucial for future educators and scientists.</p>`,
    `<p className="text-xl font-semibold">BSc in physics major and mathematics minor.</p>
    <p className="text-base my-2">Students pursuing a Bachelor of Science in Physics with a minor in Mathematics at the Faculty of Education will develop a deep understanding of fundamental physical principles and advanced mathematical techniques. The curriculum combines rigorous coursework in classical mechanics, electromagnetism, quantum mechanics, and statistical physics with extensive training in calculus, linear algebra, and differential equations. This interdisciplinary approach equips students with analytical and problem-solving skills essential for addressing complex scientific questions. Additionally, the program emphasizes pedagogical strategies, preparing graduates to effectively teach and inspire future generations in both physics and mathematics. Practical laboratory experiences and research opportunities further enhance students' ability to apply theoretical knowledge in real-world contexts.</p>`,
    `<p className="text-xl font-semibold">BSc in  Mathematics major and Physics minor.</p>
    <p className="text-base my-2">Students pursuing a Bachelor of Science in Mathematics major with a Physics minor at the Faculty of Education will gain a robust foundation in both theoretical and applied mathematics, alongside a solid understanding of fundamental physics principles. The program is designed to cultivate analytical and problem-solving skills, preparing students to tackle complex mathematical problems and understand the physical laws governing the universe. Coursework will include advanced calculus, linear algebra, and differential equations, complemented by studies in classical mechanics, electromagnetism, and quantum physics. Additionally, students will explore educational theories and teaching methodologies, equipping them with the tools necessary to effectively communicate complex scientific concepts in an educational setting. This interdisciplinary approach ensures graduates are well-prepared for careers in education, research, and various scientific industries.</p>`,
    `<p className="text-xl font-semibold">BA in Geography and history.</p>
    <p className="text-base my-2">Students in the Bachelor of Arts in Geography and History at the Faculty of Education will explore the dynamic interplay between human societies and their environments, covering key topics in both physical geography and historical events. The program emphasizes critical thinking, research, and analytical skills, supplemented by practical fieldwork. Pedagogical training prepares students to teach these subjects effectively, making them well-equipped for careers in education, research, and public policy.</p>`,
    `<p className="text-xl font-semibold">BA in Arabic language and English.</p>
    <p className="text-base my-2">Students enrolled in the Bachelor of Arts in Arabic Language and English at the Faculty of Education will immerse themselves in the comprehensive study of both languages, gaining proficiency and deep cultural insights into each. The program emphasizes advanced linguistic skills, including reading, writing, and speaking, in both Arabic and English. Students will explore classical and modern literature, linguistics, and the rich historical and cultural contexts that shape these languages. Additionally, the curriculum includes courses on language pedagogy, equipping future educators with effective teaching strategies and methodologies. By integrating theoretical knowledge with practical application, the program prepares graduates to become skilled bilingual educators, translators, and communicators, capable of bridging cultural divides and enhancing language education in diverse settings.</p>`
  ],
  desc2:
    "In the near future the faculty is planning to add those two departments",
  dep2: [
    "BA in Social work and social administration",
    "BA in English Language and literature",
  ],
};

export const csDept = {
  desc: "At faculty of Computer science & IT, Curently we offer the following programs",
  dep: [
    "Department of Computer Science",
    "Department of Information Technology",
    "Department of Business Information Technology",
  ],
};

export const engDept = {
  desc: "At faculty of Engineering & Technology, Curently we offer the following programs",
  dep: [
    "Architectural Engineering",
    "Electrical and Electronic Engineering",
    "Civil Engineering",
    "Mechanical Engineering",
  ],
};

export const slDept = {
  desc: "At faculty of Shari’a and Law, Curently we offer the following programs",
  dep: [
    "Bachelor of Shari’a & Law.",
    "Bachelor of Law in English(LLB).",
    "Bachelor of Principles of Islamic Jurisprudence (fiqhu wa usulahu).",
    "Bachelor of Islamic Studies. ",
  ],
};

export const agrDept = {
  desc: "The faculty pursues its core objectives under six academic departments, namely:",
  dep: [
    "Department of Crop Protection",
    "Department of Crop Production",
    "Department of Animal Production",
    "Department of Agricultural Engineering",
    "Department of Soil Sciences",
    "Department of Agriculture Economics and Management",
  ],
};

export const vetDept = {
  desc: "The faculty of veterinary science has two integrated departments:",
  dep: ["Animal Health", "Animal Husbandry"],
};

export const hsDept = {
  desc: "The faculty of Health science offers the following departments:",
  dep: [
    "Batchelor of Medical Laboratory Science",
    "Batchelor of Nursing",
    "Batchelor of Midwifery",
    "Batchelor of Public Health",
    "Batchelor of Pharmacy",
    "Batchelor of Nutrition ",
  ],
};

export const ecoDept = {
  desc: "We have eight departments within the faculty, giving you ample choice. These include the following:",
  dep: [
    "Business Administration",
    "Economics",
    "Banking and Finance ",
    "Accounting",
    "Procurement and logistics Management ",
    "Statistics and Planning ",
    "Human Resource Management",
  ],
};

export const geoDept = {
  desc: "The Faculty of Geoscience and Environment has the following departments",
  dep: ["Geology", "Environmental Science"],
};

export const denDept = {
  desc: "The Faculty of Dentistry at benadir university offers only one program",
  dep: [
    `<p className="text-xl font-semibold">Dental Medicine</p>
    <p className="text-base my-2">The Faculty of Dentistry offers comprehensive education and training in dental medicine, focusing on both theoretical knowledge and practical skills. Students are equipped with the expertise to diagnose, prevent, and treat oral health conditions. The curriculum covers various specializations such as orthodontics, oral surgery, and pediatric dentistry, ensuring graduates are well-prepared for clinical practice. Additionally, the faculty emphasizes research and community service, contributing to advancements in oral healthcare.</p>` ],
};

export const psDept = {
  desc: "The Faculty of Social Science has the following departments:",
  dep: ["Department of Political science and international relations","Department of Public Administration"],
};

export const msDept = {
  desc: "The faculty of Marine Sciences and Nautical Studies houses three departments:",
  dep: [
    "Department of Maritime Management.",
    "Department of Fishery.",
    "Department of Nautical Science and Maritime Transportation",
  ],
};
