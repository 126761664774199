export const pubs = [
    { id: 1, year: "2023", item: [
        {id: 1,   title: "A Late Diagnosis of Visceral Leishmaniasis Using Tru-Cut Biopsy of the Spleen and Malaria Co-Infection – A Diagnostic Challenge: A Case Report in Somalia", doi: "https://doi.org/10.2147/IDR.S420832"},
      ]},
      { id: 2, year: "2024", item: [
        {id: 1,   title: "Knowledge and practice of mother kangaroo care among nurses in public hospitals in Somalia", doi: "https://doi.org/10.1016/j.jnn.2023.12.003"  },
        {id: 2,   title: "Obstetric transition: trends in maternal mortality in Somalia over the last two decades", doi: "https://doi.org/10.1093/inthealth/ihad121" },
        {id: 3,   title: "Knowledge, Attitude and Practice of Toothbrush Contamination and Disinfection Among Undergraduate Students in Selected Universities in Somalia", doi: "https://doi.org/10.2147/CCIDE.S448793" },
        {id: 4,   title: "A team of Benadir University lead by the rector and director of Innovation published a paper on SDGs", doi: "https://doi.org/10.1186/s12889-024-18319-x" },
        {id: 5,   title: "Our experience with blast and gunshot induced traumatic vascular injuries at Somalia's major vascular referral center", doi: "https://doi.org/10.1038/s41598-024-63686-5" },
        {id: 6,   title: "Depression among general outpatient department attendees in selected hospitals in Somalia: magnitude and associated factors", doi: "https://doi.org/10.1186/s12888-024-06020-7" },
      ]}, 
]

