import eng1 from "../assets/images/faculties/eng/1.jpg";
import mr1 from "../assets/images/faculties/ms/1.jpg";
import geos from "../assets/images/faculties/geos/1.jpg";
import hs from "../assets/images/faculties/hs/1.jpg";
import md from "../assets/images/faculties/md/1.jpg";
import shl from "../assets/images/faculties/shl/1.JPG";
import ss from "../assets/images/faculties/ss/3.jpg";
import ec from "../assets/images/faculties/ec/1.JPG";
import cs from "../assets/images/faculties/cs/1.PNG";
import ag from "../assets/images/faculties/ag/1.PNG";
import vet from "../assets/images/faculties/vet/1.jpg";
import fyImgShowcase from "../assets/images/faculties/fy/fy-showcase.jpg"


const desc = `In order to provide the best and highest quality education to our students, the Faculty of Medicine is equipped with education and research laboratories with the highest level of technology. Students find all kinds of research and study opportunities in Molecular Medicine, Biochemistry, Neurological Sciences, Cell Culture, Microbiology,
Neurophysiology, and Hemodynamics Laboratories. There is an advanced cadaver room as well as a comprehensive model
laboratory designed for anatomy education. Physiology Education is provided with computerized data, recording, and
experiment systems, and each student is provided with separate microscopes and training sets. In addition, it allows
each student to actively participate in the experiments; A separate training laboratory where biochemical and molecular
analyzes, metabolism analyzes, and structural and biochemical analyzes of microorganisms are carried out is located in the infrastructure of the faculty.`;

export const medBackground = {
  bg: [
    "The organization of the faculty is based on basic sciences, professional courses, projects in different disciplines, and offering specific courses that collectively form the curriculum. While most of the courses in basic sciences are taken during the first two years of study, the professional courses of three years will be dealt with for preclinical and clinical study, and the Core Clinical Clerkship deals with the rest of the professional academic years of study. The Faculty of Medicine and Surgery was established in 2002 and has developed into an internationally recognized medical school serving the Somali Republic.",
    "The Bachelor of Medicine and Bachelor of Surgery (MBChB) degree program at Benadir University (BU) takes five years to complete. The Health Professions Council of Somalia allows students who graduate with an MBChB and complete a one-year internship with community service to practice as medical doctors.",
  ],
  vision: [
    "The Faculty of Medicine aims to provide students with the necessary skills and experience in the delivery of healthcare to patients with the most competent care afforded to them.",
  ],
  mission: [
    "The mission of the Faculty of Medicine is to promote professional excellence and to expand knowledge of human life processes. Both in health and illness, encourage preventive health behavior and promote a healthy lifestyle, and share such learning with all those with whom you come into contact, including the community.",
  ],
  values: ["Quality: that is reflected in the innovative conduct and advancement of education, research and patient care.", 
  "Admiring: for individuals who are affiliated with, or come with contact, benadir university faculty of medicine and surgery: staff, students, residents, fellows, faculty, partners, communities, patients and families..", 
  "Principles: acceptance the very highest standard of ethical behaviours and exemplary moral character. ",
  "Heterogeneity: That reflected in actions that appreciate all individuals.",
  "Partnership: that is manifested by collegial communication and collaboration."],
  EducationalOpurtunites: { desc: desc, img: md },
  careersAreas: [
    `Basic science & Clinical medicine: This includes a wide range of specialties, such as general practice, pediatrics, internal medicine, surgery, and obstetrics and gynecology. Clinical physicians are responsible for the direct care of patients, including diagnosing and treating diseases, prescribing medications, and providing preventive care.
    Administration: Graduates of the Faculty of Medicine and Surgery can also pursue careers in healthcare administration. Healthcare administrators oversee the day-to-day operations of hospitals, clinics, and other healthcare organizations.
        Public health: Graduates of the Faculty of Medicine and Surgery can also pursue careers in public health. Public health professionals work to improve the health of the population as a whole by developing and implementing programs to prevent disease, promote health, and protect the environment.`,
  ],
};

export const eduBackground = {
  bg: [
    "In September 2003, Benadir University opened the Faculty of Education to strengthen the knowledge of students from secondary schools in Somalia and to find teachers with qualified knowledge and skills after noticing a gap in the quality of students from secondary schools, especially in science.",
    "Ever since, the university has embarked on a journey to graduate the best academic professionals in education with the research and critical thinking skills required to uplift regional and national communities in Somalia.",
    "Additionally, the faculty has gone on to inspire knowledgeable and reflective life-long learners who care about the welfare of others and are committed to the very best in teaching and learning.",
  ],
  vision: [
    "To endeavor to be a leading faculty in the preparation and development of teachers of the highest quality and the promotion of research as well as innovative and critical skills required to uplift regional, provincial, and national communities.",
    "Raising teacher's skills and knowledge means raising the culture and development of society."
  ],
  mission: [
    "The Faculty of Education at Benadir University aims to inspire knowledgeable and reflective long-life learners who care about the welfare of others and the environment and are recognized for their leadership and commitment to the very best in teaching and learning.",
  ],
  values: [
    "Excellence",
    "Innovation",
    "Dignity and Integrity",
    "Justice and Equality",
    "Cooperation",
    "Empowerment",
  ],
  EducationalOpurtunites: { 
    desc: `The BU Faculty of Education has distinguished five core values and beliefs:
      •	Excellence in Teaching & Learning.
      •	Respect & Commitment.
      •	Personal & Social Responsibility.
      •	Community Engagement.
      •	Sustainability of Resources.
    Join us and enjoy a sophisticated cultural community based on respect and appreciation, high level experienced professors, effective research, field practicing, library and labs`, 
    img: "hs" },
  careersAreas: ["Excellence", "Caring", "Services"],
};

export const cssBackground = {
  bg: [
    "The Faculty of Computer Science and Information Technology (CIT) was established in 2003. A combination of highly qualified faculty members and state-of-the-art facilities has established the faculty as one of the leading and most prestigious computer science and information technology faculties in the country. The competency of the faculty is evident from the achievements of the alumni, who have created a brand value both in the job sector and academia, both in and outside of the country.",
    "The faculty is especially proud of the quality of its teaching methods and the diversity of the computer equipment available for students' use, as it maintains respective modern computer facilities.",
  ],
  vision: [
    "To stay at the top of Computer Science and IT in Eastern Africa and work towards world-class, distinguished faculty in high-quality teaching and research. So, the Faculty of Computer Science & IT strives to continue to be the premier faculty for whole computing technology in Somalia and to become internationally recognized for excellent education for students while contributing to the national economy through research and transfer to industry.",
  ],
  mission: [
    "The mission of the Faculty of Computer Science & IT is to produce the best-quality computer science and IT professionals by offering a broad-based education, encouraging life-long learning, fostering teamwork and leadership, and promoting creativity and competitiveness. Furthermore, pursue creative research and new technologies in computer science and across disciplines to serve the needs of industry, government, and society.",
  ],
  values: [
    "Commitment to high quality education and student success.",
    "Quality assurance of academic programs through disciplinary accreditation.",
    "We believe computer science is foundational for all students.",
    "We act with integrity and transparency.",
    "We are committed to equity, access, and opportunity.",
  ],
  EducationalOpurtunites: {
    desc: `Programming: You will learn how to code in a variety of programming languages, such as Python, Java, and C++.
    Data structures and algorithms: You will learn how to design and implement efficient data structures and algorithms to solve problems.
    Software engineering: You will learn how to design, develop, and test software applications.
    Computer architecture: You will learn about the hardware and software components that make up computers and how they work together.
    Operating systems: You will learn about the operating systems that manage computer resources and allow users to interact with computers.
    Networking: You will learn about the networks that connect computers together and how they allow them to communicate with each other.
    Databases: You will learn about the design, implementation, and use of databases to store and manage data.
    Artificial intelligence: You will learn about the principles and techniques of artificial intelligence, such as machine learning and natural language processing.`,
    img: cs,
  },
  careersAreas: [
    `Software engineering: Software engineers design, develop, and test software applications. They work with a variety of programming languages and technologies to create software that meets the needs of users.
        Web development: Web developers design, develop, and maintain websites and web applications. They use a variety of technologies, such as HTML, CSS, and JavaScript, to create websites that are both functional and visually appealing.
        Information security: Information security analysts protect computer systems and networks from cyberattacks. They use a variety of tools and techniques to identify and mitigate security risks.
        Computer networking: Computer network engineers design, implement, and maintain computer networks. They work with a variety of hardware and software components to create networks that are reliable and efficient.
        Computer graphics: Computer graphics artists create and manipulate digital images and videos. They use a variety of software tools to create realistic and visually appealing graphics.`,
  ],
};
export const engBackground = {
  bg: [
    "The Faculty of Engineering at Benadir University was established in 2003 with the aim of providing high-quality engineering education and research to students in Somalia and the surrounding region. The Faculty of Engineering offers undergraduate programs in Civil Engineering, Electrical and Electronic Engineering, and Architectural Engineering. The programs are designed to provide students with a strong foundation in engineering principles and practices as well as prepare them for the challenges of the engineering profession.",
  ],
  vision: [
    "The vision of the Faculty of Engineering at Benadir University is to be a leader in engineering education and research in the region, known for producing graduates who are prepared to make a positive impact in their communities and in the engineering profession.",
  ],
  mission: [
    "The faculty mission is to be a leader in engineering education and research that prepares graduates to make a positive impact in their communities and in the engineering profession, while promoting innovation, ethics, sustainability, and community engagement.",
  ],
  values: [
    "The faculty core values include excellence, innovation, ethics, sustainability, community, professionalism, and diversity. These core values guide our faculty's operations and activities, and they reflect our commitment to providing a comprehensive and innovative engineering education that prepares graduates to make a positive impact in their communities and in the engineering profession.",
  ],
  EducationalOpurtunites: {
    desc: `Experience a world of educational possibilities in engineering at Benadir University's Faculty of Engineering Technology. Our modern classrooms provide an engaging learning atmosphere, equipped with the latest facilities and resources. Discover our lively campus, featuring an organized library that fuels your thirst for knowledge. Take advantage of our inviting spaces to connect with fellow students and nurture your passion for engineering. Apply to Benadir University today and embark on an enriching academic journey that propels you towards a bright future in the field of engineering.`,
    img: eng1,
  },
  careersAreas: [
    `Civil Engineering: As a civil engineer, you can specialize in various fields such as structural engineering, transportation engineering, environmental engineering, and construction management. You'll contribute to the design, planning, and construction of infrastructure projects like buildings, bridges, roads, and water systems. Civil engineers play a vital role in ensuring the safety, efficiency, and sustainability of our built environment.

        Electrical Engineering: In the field of electrical engineering, you can pursue diverse career paths. From power systems and electronics to telecommunications and renewable energy, electrical engineers are at the forefront of technological advancements. You'll design and develop electrical systems, work on cutting-edge technologies, and contribute to areas like renewable energy integration, automation, and telecommunications networks.`,
  ],
};

export const slBackground = {
  bg: [
    "To complete its growth process in higher education, the university established the Faculty of Shari’a and Law in November 2009–2010 to react to society's need for public and civil organizations to have lawyers with expertise in Islamic Sharia. ",
    "Modern laws began when the university was able to produce consecutive batches of graduates from different colleges in various fields of science. ",
    "The Faculty of Law is intertwined with the Faculty of Science and Arts to provide the nation with law, order, security, and good governance capable of implementing Islamic Sharia.",
  ],
  vision: [
    "The faculty is projected to become the leading provider of forensic and law science, with the use of academic curriculum that combines the authenticity of a distinct Islamic heritage and the latest developments of the modern laws. ",
  ],
  mission: [
    "Provide Shari’a and law education and distinguish between them according to the scientific standards agreed upon internationally.",
    "Get deeper into the understanding of the purposes of Shari’a and law studies and introduce the capacity to compare between them. ",
    "Prepare a generation proud of the Islamic values; build their capabilities and skills of scientific research within the Boundaries of Islamic law. ",
    "Upgrade the membership of the faculty and encourage scientific research and development. ",
    "Contribute the community with legal awareness and raise their legitimacy under the Shari'a and Law",
  ],
  values: [
    "Honesty and integrity",
    "Moral uprightness",
    "Self-discipline",
    "Transparency",
    "Equity",
    "Professionalism",
    "Respect to work and duty",
    "Providing services to society in a respectable manner.",
    "Legal advice and justice",
  ],
  EducationalOpurtunites: {
    desc: `1.	Advanced theoretical study in line with specialization requirements. 
  2.	Sharia and law training while studying in the moot court at the university campus following a curriculum designed to respond the needs of the students.
  3.	Legal training for bodies working in law, such as: local courts, governorate court, court of appeal, criminal investigation, and public prosecution.
  4.	Building the student’s personalities on holding courses, seminars and scientific meetings to improve their careers.`,
    img: shl,
  },
  careersAreas: [
    `Among the opportunities available to graduates of the College of Sharia and Law: 
  1. Working as a lawyer
  2. Being a judge
  3. Working in arbitration centers
  4. Becoming a public prosecutor
  5. Working in public notary
  6. Working as a legal advisor
  `,
  ],
};

export const agrBackground = {
  bg: [
    "The Faculty of Agriculture at Benadir University was established on August 15, 2008, to provide different courses in Agricultural Sciences to generate qualified personnel for the national and international agricultural organizations, to cover the academic needs of agriculture, and to review and solve the problems encountered by the farmers’ communities. A four-year B.S. program in agriculture science is studied during the four years. The Faculty Academic Council (FAC), the Dean of the Faculty, the Secretary, well-qualified academic staff and their assistants, and other staff of the Faculty do their best to prepare a suitable and convenient academic environment for the students.",
  ],
  vision: [
    "To be one of the leading centers of excellence for teaching, training, research, and the dissemination of knowledge in sustainable agriculture in East Africa",
  ],
  mission: [
    "To provide innovative teaching and services to the students and the local communities",
    "To conduct demand-driven research and disseminate appropriate technologies to farmers and relevant policy-making institutions.",
    "To build the bridge between agribusiness and academia.",
    "",
  ],
  values: ["Transformation", "Integrity", "Excellence", "Equality"],
  EducationalOpurtunites: { desc: desc, img: ag },
  careersAreas: ["Excellence", "Caring", "Services"],
};

export const vetBackground = {
  bg: [
    `The Faculty of Veterinary Science of Benadir University was established in September 2011 with the aim of training skilled manpower in veterinary medical profession.
        The faculty provides adequate training of man power to meet the country’s’ demands by conducting educational programs leading to the attainment of professional degrees in veterinary medical disciplines. 
        In addition to its teaching programs, the faculty also provides diagnostic and consultancy services in animal health problems, conduct research in livestock diseases and production with the final goals of increasing the food resource of Somalia by enhancing livestock productivity and health.
        `,
  ],
  vision: [
    `To be among the most prestigious veterinary colleges in the world in the areas of veterinary education, animal health care, and cutting-edge scientific research, as well as to possess the exceptional qualities necessary for community service and advancement in all veterinary professions.`,
  ],
  mission: [
    `To provide a learning environment in which faculty, staff and students can discover, examine critically, preserve and transmit the knowledge, wisdom and values that will help survival of this golden future generation and improve the quality of life for all.`,
  ],
  values: [
    "Honesty",
    "Commitment",
    "Open-mindedness",
    "Consistency",
    "Efficiency",
    "Innovation",
    "Patriotism",
    "Respect",
  ],
  EducationalOpurtunites: {
    desc: `
    The Faculty of Veterinary Is Outfitted to give our students the greatest and highest caliber education possible.
    The faculty has well equipped veterinary diagnostic laboratory (Microbiology sector, Hematology, Cytology, Serology, Histology).
    Vet clinic (Medication, Vaccination, Surgery, Obstetric service, training and consultation)
`,
    img: vet,
  },
  careersAreas: [
    `Veterinary science graduates can be employed in different areas: -
       •	Private Sector
       •	Public Sector
       •	NGOs
       •	International staff  
       •	Academician 
       •	Clinical service
       •	Preventive service
       •	Quality control
       •	Meat inspection
       •	Local government 
       •	Medical research institutes
       •	Natural science laboratories
       •	Pharmacy
       •	Fodder Production
       `,
  ],
};

export const hsBackground = {
  bg: [
    "The faculty of health science (FHS) was established jointly under the competent leadership group of dedicated members by both Doctors worldwide Turkey (DWWT) and Benadir University (BU) in an academic year 2013/2014. This was a result of strong values and mutual partnership among DWWT and BU to accomplish their goals. Yet again, the idea to creating this faculty was obligated the realization to consequences of fading mid-level health workforce in Somalia. This main motive was guided by philosophy ‘to serve humanity is to serve the almighty’.",
  ],
  vision: [
    "To provide one of the best leading healthcare institutions in the region providing outstanding treatment, health services and quality medical education.",
  ],
  mission: [
    "To produce a cadre of high-quality health workers equipped with knowledge, practice, and high moral values, dedicated for suffering human physically and psychologically in the nation and across the geographical boundaries.",
  ],
  values: [
    "Commitment to integrity and Ethical",
    "Excellence",
    "Caring",
    "Services",
    "Empathy",
    "Respect",
    "Knowledge",
  ],
  EducationalOpurtunites: {
    desc: `The health science field offers a diverse range of career options. You can pursue clinical jobs to interact directly with patients and address their needs, along with non-clinical jobs to support the health and well-being of the population. Learning more about careers in health science, including their typical responsibilities and requirements, can help you find the path that best aligns with you and your interests. 
  Health science represents a sector of the healthcare industry that applies science to health-related topics. Professionals in this field may combine science, technology, and healthcare knowledge to support people's health and overall well-being. Health science offers diverse career paths, and individuals can work in both clinical and non-clinical settings. Individuals who choose clinical professions often need to gain advanced degrees and experience. Health science professionals may find employment in the following places:
  •	Hospitals
  •	Health-care facilities
  •	Clinics
  •	Medical laboratories
  •	Pharmaceutical companies
  •	Government agencies
  •	Nonprofit organizations
  `,
    img: hs,
  },
  careersAreas: [
    `
  •	Behavioral Health Researcher
  •	Biostatistician
  •	Clinical Research Coordinator
  •	Clinical Social Worker
  •	Dietitian
  •	Emergency Medical Technician
  •	Health Care Administration
  •	Health Educator
  •	Health Science Writer
  •	Medical Record Technician
  •	Patient Care Administrator
  •	Pharmacy Technician
  •	Public Health Consultant
  •	Research Technician
  `,
  ],
};

export const ecoBackground = {
  bg: [
    "The Benadir University Faculty of Economics and Management (FEMA) was established in 2014 as a multidisciplinary new unit to address the current and specific needs of Somalia's economy, socioeconomic skills, and strategic development.",
    "Since its establishment, the faculty has been tremendously successful in training students for careers in the private sector, public administration, and non-governmental organizations (NGOs).BU FEMA aims to prepare the future managers, policymakers, and leaders of the nation.",
    "In addition, the faculty will provide short-term courses as well as centers of excellence in post-conflict recovery and governance (PCRG) research.",
  ],
  vision: [
    "To be the center of excellence in quality education in the fields of economics, banking, and finance, and management through teaching, learning, research, and community service. ",
  ],
  mission: [
    "To create an academic environment that promotes teaching and learning skills and sustains high professional standards.",
  ],
  values: [
    "Transparency",
    "Accountability",
    "Responsibility and Professionalism",
  ],
  EducationalOpurtunites: {
    desc: `The Faculty of Economics and Management offers various academic opportunities for students interested in pursuing studies in the field. These opportunities include academic programs, research opportunities, and professional development. 
    Undergraduate research opportunities, Graduate assistantships, international exchange programs, Academic advising and Graduate programs (Postgraduate Diploma and Master Degree Programs related your fields: Accounting and Finance, Business Administration, Economics, Procurement and Logistics, Banking and Finance, Project Management and Applies statistics and Research Methodology) as they will develop skills in critical thinking, problem-solving, communication,  leadership abilities and ethical decision-making.`,
    img: ec,
  },
  careersAreas: [
    "Graduating from a Faculty of Economics and Management can open up various opportunities for individuals including these areas : ",
    "Business and Corporate Careers",
    " Financial Services",
    " Consulting: Government and Public Sector",
    " Entrepreneurship and Startups",
    " Research and Academia",
    " International Organizations and NGOs",
    " Data Analysis and Market Research",
    " Project Management",
    " banks and financial institutions",
    " investment companies and Economic Analysis",
    " operations management",
    " supply chain management",
    " procurement and logistics distribution",
    " transportation",
  ],
};

export const geoBackground = {
  bg: [
    `The Faculty of Geoscience and Environment may be considered relatively young since its establishment in August 2015. Nevertheless, it has experienced rapid and impressive growth within a short span of time. The faculty has made impressive progress by recruiting qualified lecturers and obtaining the necessary equipment to deliver high-quality education. Additionally, they have also gathered a wide range of resources, such as books, research journals, and rock and mineral samples.  Notably, the faculty has acquired a library containing over a hundred geology books and research journals starting from 1976 to the present, which is a remarkable accomplishment.`,
  ],
  vision: [
    "Address fundamental geosciences and environment questions regarding Earth’s transitions over space and time. We will lead research in the areas of Earth science that directly relate to societal challenges of the 21st century, including water, energy, natural hazards, natural resources, climate, life, land use, environment, and soils.",
    "Foster a diverse community of scholars that includes collaborative research groups that promote transformative research at the interfaces between traditional disciplines. We will develop new programs and capabilities at the state, national, and international levels.",
    "Provide world-class education for students at all levels by involving them in research, offering comprehensive, innovative curricula and field and practical experiences, focusing on student learning outcomes, and preparing them for successful careers so they can create, innovate, and lead the geosciences into the future.",
  ],
  mission: [
    "It is the mission of the Faculty of Geosciences and Environment of Benadir University to provide a high-quality learning environment that cultivates understanding of the Earth system and Environment, including its processes and the role of human activity. We are committed to advancing challenging, experiential, and engaging programs of study for our students. Our graduates are well prepared to enter graduate and professional programs, the field of teaching, and a wide array of careers in government and the private and not-for-profit sectors.",
  ],
  values: [
    "Excellence: We strive for excellence in all that we do, including teaching, research, and service.",
    "Inclusivity: We are committed to creating an inclusive and welcoming learning environment that respects and values all individuals.",
    "Innovation: We encourage innovation and creativity in teaching, research, and problem-solving.",
    "Sustainability: We place a strong emphasis on sustainability and environmental responsibility in all our activities.",
    "Ethical Conduct: We are committed to upholding the highest standards of ethical conduct in all our activities.",
  ],
  EducationalOpurtunites: {
    desc: `To ensure our students receive the highest quality education, the Faculty of Geoscience and Environment is well-equipped with advanced technology in our education and research laboratories.
        The faculty of Geosciences at Benadir University is one of the best Geosciences faculties in the country. Our dedicated faculty, staff, and students are actively engaged in relevant science, ranging from studying climate change to exploring the fundamental processes that shape Earth and other planets.
        
        We prioritize excellence through state-of-the-art research and utilizing experiential teaching methods. Moreover, we strongly believe in making Geosciences accessible to all and are committed to fostering a fair, diverse, inclusive, and equitable environment for our students, faculty, and staff.
        `,
    img: geos,
  },
  careersAreas: [
    "Geologists have a wide range of opportunities in various sectors, including both private companies and public administration. They are involved in studying and managing the geological environment, exploring and evaluating natural resources, addressing geological and environmental risks, applying technology to infrastructure projects, and contributing to training, research, and development activities.",
    "Geologists also play a crucial role in understanding and working with minerals, oil and gas, soil, agriculture, energy resources, fossils, oceans and freshwater, the atmosphere, weather, the environment, natural hazards, and even space exploration.",
  ],
};

export const denBackground = {
  bg: [
    "Under the sponsorship of the Benadir Foundation, the board of directors issued a decree to establish, for the first time in post-conflict Somalia, a faculty of dentistry in 2016 as part of Benadir University’s chain of faculties to produce dental professionals that have the knowledge and capacity to promote and manage the dental healthcare needed by Somalis.",
  ],
  vision: [
    "To be a dental institution that cultivates human talent through education.",
  ],
  mission: [
    "Provide lifelong learning opportunities and exceptional service to impact dental health care nationally and globally.",
  ],
  values: [
    "Integrity",
    "Collaboration & teamwork ",
    "Continuous improvement ",
    "Perseverance ",
    "Leadership",
  ],
  EducationalOpurtunites: {
    desc: `In order to provide the best and highest quality education to our students, the Faculty of Dentistry is equipped with education and dental laboratories with the highest level of technology.
    
    Students find all kinds of dental skills and study opportunities in Molecular Medicine, Biochemistry, dental anatomy Neurological Sciences, Cell Culture, Microbiology, Neurophysiology, and Hemodynamics Laboratories, dental materials, endodontics and prosthodontics. There is an advanced dental and clinical laboratory room. 
    
    Students are prepared 6 years to be highly skilled and fully independent dental surgeon from the day the he or graduates from Benadir University.`,
    img: "hs",
  },
  careersAreas: [
    "Specialist Oral Maxillofacial surgeon.",
    "Orthodontist.",
    "Conservative dentist.",
    "Endodontist",
  ],
};

export const psBackground = {
  bg: [
    `The Faculty of Social Science is an academic division within a university at Benadir University institution that focuses on the study of various disciplines related to human society and social behaviour. It encompasses a wide range of fields such as political science, International Relations, Public Administration, and more.`,
  ],
  vision: [
    `The Social Science Department at Benadir University is dedicated to providing a dynamic and inclusive learning environment that fosters the holistic development of students, promotes critical thinking, and prepares them to address the complex social challenges of our time. We are committed to offering high-quality education, conducting impactful research, and actively engaging with communities to contribute to the advancement of knowledge and the betterment of society.`,
  ],
  mission: [
    "Our vision is to be a leading social science department that fosters critical thinking, interdisciplinary collaboration, and innovative research to address complex social challenges and promote positive societal change. ",
  ],
  values: [
    "Role of Social Science",
    "Insights into Human Existence",
    "Values and Compassion",
    "Democratic Practice and Informed Decision-Making",
    "Social Cohesion and Cultural Understanding",
    "Catalyst for Innovation and Progress",
    "Contribution to Societal Betterment",
  ],
  EducationalOpurtunites: {
    desc: `The Faculty of Social Science offers a wide range of educational opportunities,
    including undergraduate and graduate programs. Students can pursue degrees in
    disciplines such as, Political Science, International Relation, Public Administration and more. The faculty also provides opportunities for interdisciplinary studies and
    research, allowing students to explore the intersections between different social
    science fields. `,
    img: ss,
  },
  careersAreas: [
    "Graduates from the Faculty of Social Science have diverse career opportunities available to them. They can pursue careers in academia, research institutes, government agencies, non-profit organizations, United Nations, IGO’s, NGO’s, consulting firms, and various industries. The skills and knowledge acquired through social science education, such as critical thinking, research skills, data analysis, and understanding of human behaviour, are highly valued in a wide range of professions. ",
  ],
};

export const msBackground = {
  bg: [
    "Somalia has 3,333 km of coastal line, and there is a crucial need for marine science and nautical studies experts. In response to this critical need, Benadir University took the initiative and established the faculty of Marine Science and Nautical Studies in September 2021. The faculty aims to provide students with knowledge, competencies, skills, and awareness of a variety of subjects linked together by a common theme of marine science and nautical studies in response to the actual need for this field of knowledge in the country.",
    "In addition, the faculty aims to be a research center of excellence in marine science and nautical studies management.",
  ],
  vision: [
    "The vision of the faculty is to provide students with knowledge, competencies, skills, and awareness of a variety of subjects linked together by the common theme of maritime studies and marine science in response to the actual need for this field of knowledge in the country. The faculty also aims to be a center of excellence for research in maritime management and marine science.",
  ],
  mission: [
    "To produce specialized and highly qualified graduates with competitive skills that will contribute to Somali marine resources.",
    "To develop and enhance the professional skills of managers and officers who are working in Somali maritime studies and marine science,",
    "To be the center for research and consultancy in Somali maritime and marine science.",
  ],
  values: [
    "Learning. Student learning and development are the primary purposes of the university",
    "Excellence. BU promotes quality and excellence in its staff and faculty members through its well-designed professional development activities.",
    "Responsibility. BU places a high value on being ethical in all practices.",
    "Adaptability. Preparing students for a technologically changing world.",
    "Integrity. Operating with fairness, honesty, and the highest ethical standards to sustain a community of trust.",
    "Educational access. Provide all qualified students with access to graduate and postgraduate education.",
  ],
  EducationalOpurtunites: {
    desc: `	Marine Science graduates study of seas, oceans, and coastal habitats.
  	Marine Science graduates perform research, laboratory testing, instruction, marine conservation work, fisheries management, or work related to fisheries microbiology and biochemistry, fish population dynamics, fish ecology, or aquatic pollution control and Maritime studies.
  	Marine Science graduates often work as Marine scientists, marine researchers at governmental institutions, universities, related industries, non-governmental organizations, or in the private business sector.
  	Marine Science graduates may work as marine teachers, fisheries laboratories specialists, or as marine life conservationists in all different sectors.
  `,
    img: mr1,
  },
  careersAreas: [`Here are some career areas within marine science and nautical studies:
  1. Marine Biologist: Study marine organisms and ecosystems, conducting research on topics like marine biodiversity, conservation, and ecology.
  2. Oceanographer: Investigate various aspects of the ocean, including its physical, chemical, and geological characteristics. Subfields include physical oceanography, chemical oceanography, and marine geology.
  3. Marine Engineer: Design, develop, and maintain various marine structures, vessels, and systems. This can involve working on ships, offshore platforms, and other marine infrastructure.
  4. Nautical Scientist: Focus on navigation, charting, and maritime safety. Nautical scientists may work in areas such as hydrography, meteorology, and ship operations.
  5. Fisheries Scientist: Study fish populations, behavior, and ecosystems to ensure sustainable fisheries management. This field also involves monitoring and addressing the impact of human activities on aquatic ecosystems.
  6. Maritime Archaeologist: Explore and study underwater archaeological sites, preserving and interpreting maritime cultural heritage.
  8. Maritime Lawyer: Specialize in legal issues related to maritime activities, including shipping regulations, environmental protection, and maritime accidents.
  9. Marine Environmental Scientist: Assess and address environmental issues affecting marine ecosystems, such as pollution, climate change, and habitat degradation.
  10. Ship Captain/Master Mariner: Oversee the navigation and operation of ships, ensuring safe and efficient maritime transportation.`,
  ],
};

export const fyBackground = {
  bg: `The Foundation Year was established in the academic year 2023-204 in compliance with the policies set forth by the Ministry of Education, Culture, and Higher Education of Somalia. The primary objective of the foundation year is to strengthen the capacity of Somali students in essential principles of education.

  The curriculum of the Foundation Year is designed to focus on key areas that are crucial for a wellrounded education. One of the primary focuses is on languages, including Arabic, Somali, and English. Proficiency in these languages is essential for effective communication, academic success, and broader cultural understanding.
  Another crucial aspect emphasized by the foundation year is the integration of technologies into education. In today's digital age, technological literacy is vital for students to thrive in various academic and professional pursuits. The foundation year aims to equip students with the necessary knowledge and skills to leverage technology effectively in their educational and future career endeavors.

  Critical thinking is another fundamental principle emphasized by the foundation year. Students are encouraged to develop analytical and problem-solving skills, enabling them to think critically and make informed decisions. This skill set is vital for academic success, professional growth, and active participation in a fast-paced and ever-changing world.Academic writing is also a core focus of the faculty. The ability to express thoughts, ideas, and arguments coherently and effectively in writing is crucial for academic achievement and beyond.
  The foundation year aims to enhance students' writing skills, ensuring they can communicate their knowledge and insights articulately.

  Lastly, the foundation year places great importance on Islamic ethics. Somalia being a predominantly Muslim country, the foundation year aims to instill ethical values and principles in students, aligning with Islamic teachings. This focus on Islamic ethics aims to nurture individuals who are not only academically competent but also ethically responsible and contribute positively to their communities.

  Overall, the foundation year is committed to providing Somali students with a comprehensive educational experience that enhances their language proficiency, technological literacy, critical thinking abilities, academic writing skills, and Islamic ethics. By focusing on these essential principles of education, the foundation year aims to empower students and lay a strong foundation for their future academic and personal growth.
  `,
  vision: `To establish a solid and reliable foundation for university students, serving as a pillar of knowledge
  and discipline.`,
  mission: `
    To spread essential Skills for learning improvement.
    To create strong self-confident.
    Build strong base knowledge for faculty departments.
    To get disciplined students toward classmates and BU Staffs.`,
  values: `
  Excellence.
  Strong Foundation.
  Ethical Orientation.
  Teamwork`,
  Objectives: { desc: `
  1. To equip students with the fundamental knowledge and skills required for success in higher education.
  2. To ensure that students have a solid understanding of core subjects relevant to their chosen field of study.
  3. To enhance students' critical thinking and problem-solving skills within the context of academic subjects.
  4. To teach effective study techniques, time management, and organizational skills.
  5. To help students develop research skills and the ability to evaluate and analyze information.
  6. To enhance communication skills, both written and verbal, to ensure success in academic coursework.
  7. To facilitate the adaptation of students to the cultural and social aspects of the academic environment.
  8. To foster critical thinking skills that are essential for academic success and lifelong learning.
  9. To boost students' self-confidence by providing a supportive learning environment.
  10. To guide students in making informed decisions about their future academic and professional pursuits.`, 
    img: fyImgShowcase },
    Faculties: `The following are the list of the faculties which have to take a foundation year:
    1. Faculty of Medicine and Surgery
    2. Faculty of Dental Science
    3. Faculty of Health Science
    4. Faculty of Computer Science
    5. Faculty of Engineering and Technology
    6. Faculty of Education
    7. Faculty of Sharia Law
    8. Faculty veterinary
    9. Faculty agriculture
    10. Faculty of Economics and Management
    11. Faculty geoscience
    12. Faculty of Social Science
    13. Faculty of Marine Science `,
};
