import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { FaUniversity } from "react-icons/fa";
import parse from "html-react-parser";
import Footer from "./Footer";
import Header from "./Header";
import { message, addmi } from "../../data/graduationNotice";

const Layout = ({ children }) => {
  const [togle, setTogle] = useState(true);
  const showToggle = () => {
    setTogle(!togle);
    // setSubTogle(!subTogle);
  };
  return (
    <>
      <div
        className={`bg-white border-2 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-[70%] mx-[300px] sm:mx-5 xs:mx-5 z-50  my-[80px] fixed  ${
          togle ? "" : "hidden"
        }`}
        role="alert"
      >
        <div className="flex flex-col ">
          <div className="py-1 my-2 flex flex-row justify-around">
          <FaUniversity className="text-5xl"/>
            <p className="font-bold my-2 text-2xl sm:text-xl xs:text-base">
              {addmi.title}
            </p>
            <GrClose
              className="text-3xl cursor-pointer "
              onClick={showToggle}
            />
          </div>
          <hr />
          <div>
            <p className="text-base p-2 whitespace-pre-line xs:hidden">
              {parse(addmi.body)}
            </p>
          </div>
        </div>
      </div>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
